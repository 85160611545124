<template>
    <borish-info v-if="info"
                 key="'details'" @clickPrivacy="privacyHandler"
                 :info="info"></borish-info>
</template>

<script>
    import store from "../store";
    import BorishInfo from "../components/borishInfo";

    export default {
        name: "Details",
        components: {BorishInfo},
        computed: {
            info() {
                return store.info
            }
        },
        methods: {
            privacyHandler() {
                this.$router.push({name:'Privacy', params:{menuSlug:'privacy'}})
            }
        }
    }
</script>

<style scoped>
.modal .section{
    @media (max-width: 767.98px){
        padding-top: 110px;
    }
}
</style>