<template>
    <div class="section">
        <div class="block-info">
            <div class="info-list">

                <div v-for="(item, name) in info" :key="name" :class="'info-list-item info-list-item--'+slug(name)">
                    <transition name="slide-chapter">
                        <h4 v-if="item.title" v-show="runningAnimation" v-html="item.title"
                            class="info-list-item__title"></h4>
                    </transition>
                    <transition name="slide-chapter">
                        <div v-show="runningAnimation">
                            <div v-if="item.content" v-html="item.content" class="info-list-item__content"></div>
                            <div v-if="name==='cta' && (item.title || item.content)" class="info-list-item__icons">
                                <span class="info-list-item__icon"><icon-heart></icon-heart></span>
                                <span class="info-list-item__icon"><icon-brush></icon-brush></span>
                                <span class="info-list-item__icon"><icon-videocam></icon-videocam></span>
                            </div>
                            <template v-if="item.vimeo">
                                <a v-if="item.vimeo.url" :href="item.vimeo.url" target="_blank" class="link-vimeo">
                                    <logo-vimeo-icon/>
                                    {{ item.vimeo.title }}</a>
                                <span v-else class="link-vimeo"><logo-vimeo-icon/>{{ item.vimeo.title }}</span><br/>
                            </template>
                            <ul v-if="item.social_links" class="socials">
                                <li v-for="media in item.social_links" :key="media.media">
                                    <a :href="media.url"
                                       target="_blank"
                                       rel="nofollow"
                                       class="socials__link link-vimeo">
                                        <component :is="`logo-${media.media}-icon`"/>
                                        <span v-if="media.title">{{ media.title }}</span>
                                    </a></li>
                            </ul>
                            <template v-if="item.privacy">
                                <router-link :to="{name:'Privacy'}" class="underline">{{ item.privacy.title }}</router-link>
                                <br/>
                            </template>
                        </div>

                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import slugify from "slugify"
    import animationMixin from '../mixins/animationMixin'

    export default {
        mixins: [animationMixin],
        props: ['info'],
        data() {
            return {
                blocks: ['creators', 'clients', 'genres', 'cast'],
                myData: [],
            }
        },
        mounted() {
            for (let i = 0; i < this.blocks.length; i++) {
                let key = this.blocks[i]
                if (this.info[key]) {
                    this.myData.push({[key]: this.info[key]})
                }
            }
        },
        methods: {
            slug(str) {
                return slugify(str)
            },
            emit_privacy() {
                this.$emit('clickPrivacy')
            }
        }
    }
</script>

<style scoped>
</style>
